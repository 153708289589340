'use client'

import dynamic from 'next/dynamic'
import { Grid2 as Grid } from '@mui/material'

// Types
import type { MenuProps } from '@/types/components/molecules/menu'

// Components
const ListItemsMolecule = dynamic(() => import('@/components/molecules/menu/list'))

const MenuMolecule = (props: MenuProps) => {
	// Props
	const { items } = props

	// Variables
	const newItems = items.filter(item => item.link_value !== '/' && item.link_value !== '/home')

	return (
		<Grid
			sx={{
				'& ul': {
					display: 'inline-flex',
					flexWrap: 'nowrap',
					gap: 2,
					width: 1,
					position: 'inherit',
					p: 0,
					'& li': {
						display: 'inline-flex',
						p: 0,
						pl: 7,
						position: 'inherit',
						borderRadius: 1,
						transition: 'all 0.3s ease',
						'& a': {
							display: 'inline-block',
							width: 1,
							alignItems: 'center',
							fontSize: 16,
							fontWeight: 700,
							textTransform: 'none',
							transition: 'all 0.3s ease',
							color: 'primary.dark',
							'& .MuiSvgIcon-root': {
								fontSize: 16,
								transition: 'all 0.3s ease'
							}
						},
						'&:hover': {
							bgcolor: 'inherit'
						},
						'&.has-children': {
							mb: -3,
							pb: 3
						},
						'&:hover ul': { display: 'inline-flex' }
					}
				}
			}}
		>
			<ListItemsMolecule items={newItems} />
		</Grid>
	)
}

export default MenuMolecule
